.Servicos-wrapper {
  padding: 90px 20px;
  background-color: #e8f1fb;
}

.Servicos-container {
  max-width: 1170px;
  margin: auto;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.Servicos-container h2 {
  font-size: 42px;
  color: #212a34;
  margin-bottom: 10px;
  text-align: center;
}

.Servicos-container > h3{
  margin-bottom: 20px;
  text-align: center;
  font-size: 26px;
  line-height: 1.3;
}

.Servicos-container p {
  font-size: 18px;
  line-height: 1.3;
}

.Servicos-container .column > ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #212a34;
}

.Servicos-container .column > ul > li {
  background-color: #fff;
  padding: 20px;
  border-left: 4px solid #faa31a;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 700;
  box-shadow: 10px 16px 45px -22px rgba(196, 196, 196, 0.47);
  line-height: 1.1em;

  display: flex;
  cursor: pointer;
  position: relative;
  height: fit-content;
  min-height: 85px;
}

.servicos-content-header{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between !important;
  
}

.Servicos-container .column > ul > li.active{
  flex-direction: column;
}

.columns {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 48%;
}

.Servicos-container li h3 {
  font-size: 18.5px;
  line-height: 1.2;
}

.Servicos-container li span {

}

.Servicos-container li a {
  margin-top: 10px;
  display: flex !important;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  color: #707070;
  font-size: 14px;
  border: none;
  width: 100%;
  text-transform: none;
}

.Servicos-container li a:hover {
  background-color: transparent;
  color: #707070;
  transform: scale(1.05);
}

.Servicos-container li a i {
  color: #faa31a;
  font-size: 40px;
}

.toggle-btn {
  font-size: 20px;
  cursor: pointer;
}

.servicos-content{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  color: #000;
  font-weight: 400;
}

.servicos-content p,
.servicos-content li,
.servicos-content span{
  line-height: 1.3;
}


.servicos-content li{
  margin-left: 20px;
  margin-top: 5px;
  list-style: disc !important;
}

@media(max-width:767px){
  .columns{
    flex-direction: column;
  }

  .column {
    margin-top: 20px;
  width: 100%;
}

.Servicos-container > h3{
  margin-bottom: 0px;
  font-size: 22px;
}


.Servicos-container{
  gap: 0;
}

.Servicos-container > h2{
  font-size: 32px;
}

.Servicos-wrapper{
  padding: 70px 20px;
}

}