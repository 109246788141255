
.App{
  font-family: sans-serif;
}

.whats-btn a {
    background-color: #faa31a;
    border-radius: 99px;
    bottom: 2rem;
    color: #fff;
    font-size: 60px;
    opacity: 1;
    padding: 10px;
    position: fixed;
    right: 2rem;
    transition: .4s;
    z-index: 0;
    z-index: 50;
}

.whats-btn a:hover {
    background-color: #fff;
    color: #faa31a;
}
