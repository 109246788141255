.unidade-wrapper {
  padding: 0px 20px;
  padding-bottom: 140px;

  background-color: #273978;
}

.unidade-container {
  max-width: 1170px;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.unidade-container div {
  flex: 1;
}

.unidade-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.unidade-content h2 {
  font-size: 34px;

  color: #faa31a;
}

.unidade-content p {
  font-size: 18px;
  color: #fff;
  line-height: 1.3;
}



.unidade-img video,
.unidade-img {
  border-radius: 30px;
  width: 100%;
  max-height: 400px;
}

@media (max-width: 768px) {
  .unidade-container {
    padding-top: 10px;
    flex-direction: column;
  }

  .unidade-wrapper {
    padding: 60px 20px;
  }
    .unidade-wrapper h2{
    font-size: 28px;
  }
}
