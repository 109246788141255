.sobre-wrapper {
  padding: 140px 20px;
  background-color: #273978;
}

.sobre-container {
  max-width: 1170px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.sobre-container div {
  flex: 1;
}

.sobre-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sobre-content h2 {
  font-size: 34px;
  color: #faa31a;
}

.sobre-content p {
  font-size: 18px;
  line-height: 1.3;
  color: #fff;
}

.sobre-img {
  background-image: url('../../assets/imgs/sinau1.jpg');
  border-radius: 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-color: #212a34;
  min-height: 350px;
  max-height: 350px;
}

@media (max-width: 768px) {
  .sobre-container {
    padding-top: 10px;
    flex-direction: column;
  }

  .sobre-wrapper {
    
    padding: 60px 20px;
    padding-bottom: 0px;
  }
  .sobre-wrapper h2{
    font-size: 28px;
  }
}
