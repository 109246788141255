.main-btn {
  background-color: #faa31a;
  border-radius: 4px;
  color: #ffff;
  cursor: pointer;
  font-size: 18px;
  padding: 12px 36px;
  transition: 0.3s;
  display: block;
  margin-top: 15px;
  width: fit-content;
  
}


.main-btn:hover {
  background-color: #ff9f05d3;
}

.main-btn.alt {
  background-color: #f7f7f7;
  color: rgb(73, 73, 73);
}

.main-btn.alt:hover {
  background-color: lightgrey;
}
